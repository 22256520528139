import React from "react";
import "./App.css";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { width } from "@mui/system";
import { Button, Card, IconButton, Typography } from "@mui/material";
import {
  Email,
  Facebook,
  Instagram,
  Twitter,
  YouTube,
} from "@mui/icons-material";
import { SocialIcon } from "react-social-icons";
function App() {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Grid container spacing={2} columns={3} maxWidth={840}>
        <Grid item xs={3} md={3}>
          <Box display="flex" justifyContent="center" alignItems="center">
            <img
              src="images/logo_2_1024.png"
              style={{ maxWidth: 512, maxHeight: 512 }}
            />
          </Box>
        </Grid>
        <Grid item xs={3} md={2}>
          <Card
            style={{
              padding: 10,
            }}
          >
            <Typography display={"block"} variant="h6">
              Current Project
            </Typography>
            <a href="https://store.steampowered.com/app/2345000/Vertical_Quest/" target="_blank">
              <img
                style={{ width: "100%", borderRadius: 5 }}
                src="images/vertical_quest.png"
              />
            </a>
            <div style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "10px auto" }}>
            <Button variant="contained" href="https://store.steampowered.com/app/2345000/Vertical_Quest/" target="_blank">Go To Steam</Button>
            <Button variant="contained" href="https://roadmap.inkjoy.studio/roadmap" target="_blank">Roadmap</Button>
            </div>
          
          </Card>
          <Card
            style={{
              marginTop: 18,
              padding: 10,
            }} >
            <Typography display={"block"} variant="h6">
              Other Games
            </Typography>

            <Grid container spacing={3} columns={2}>
              <Grid item xs={1} md={1}>
                <a href="https://inkjoy.itch.io/geonite">
                  <img
                    className="img"
                    src="images/TitleImage_1.png"

                  />
                </a>
              </Grid>
              <Grid item xs={1} md={1}>
                <a href="https://inkjoy.itch.io/ldjam50">
                  <img
                    className="img"
                    src="images/TitleImage_2.png"
                  />
                </a>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={3} md={1}>
          <Card style={{ padding: 10 }}>
            <IconButton color="default" href="mailto:office@inkjoy.studio">
              <Email />
            </IconButton>
            <IconButton
              color="default"
              href="https://www.facebook.com/inkjoy.studio"
            >
              <Facebook />
            </IconButton>
            <IconButton
              color="default"
              href="https://www.instagram.com/inkjoy.studio/"
            >
              <Instagram />
            </IconButton>
            <IconButton
              color="default"
              href="https://www.tiktok.com/@inkjoy.studio"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                preserveAspectRatio="none"
                style={{ width: 24, height: 24 }}
              >
                <path
                  style={{ fill: "white" }}
                  d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"
                />
              </svg>
            </IconButton>
            <IconButton color="default" href="https://www.twitch.tv/ZyrusDev">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                preserveAspectRatio="none"
                style={{ width: 24, height: 24 }}
              >
                <path
                  style={{ fill: "white" }}
                  d="M391.17,103.47H352.54v109.7h38.63ZM285,103H246.37V212.75H285ZM120.83,0,24.31,91.42V420.58H140.14V512l96.53-91.42h77.25L487.69,256V0ZM449.07,237.75l-77.22,73.12H294.61l-67.6,64v-64H140.14V36.58H449.07Z"
                />
              </svg>
            </IconButton>
            <IconButton
              color="default"
              href="https://twitter.com/inkjoy_studio"
            >
              <Twitter />
            </IconButton>
            <IconButton
              color="default"
              href="https://www.youtube.com/@inkjoy.studio"
            >
              <YouTube />
            </IconButton>
          </Card>
          <Card style={{ marginTop: 16, padding: 10 }}>
            <Typography display={"block"} variant="h6">
              Hi! I'm Zyrus
            </Typography>
            <img style={{borderRadius: 5}} src="images/pic.jpg" width={245} />
            <br />          
            <br />
            <Typography display={"block"} variant="body2">
              I love to game and make games in my
              freetime. I'm a bit of a nerd and love to learn new things.
              <br />
              <br />
              I started to make games in Unity in 2015 and I am working as
              software developer full time since 2013, so I have much knowledge
              from work that backs me up in game development.
            </Typography>
           
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default App;
